import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Driver from '../views/Driver.vue'
import Admin from "../views/Admin.vue"
import DriverAdmin from "../views/DriverAdmin.vue"
import AdminContainerList from "../views/AdminContainerList.vue"
import AdminOrderList from "../views/AdminOrderList.vue"
import OrderNoList from "../views/OrderNoList.vue"
import OrderNoSearch from "../views/OrderNoSearch.vue"
import ContainerNoList from "../views/ContainerNoList.vue"

const routes = [
  {
    path: '/d',
    name: 'Driver',
    component: Driver
  },
  {
    path: '/c',
    name: 'Home',
    component: Home
  },
  {
    path: '/a',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/da',
    name: 'DriverAdmin',
    component: DriverAdmin
  },
  {
    path: '/al',
    name: 'AdminContainerList',
    component: AdminContainerList
  },
  {
    path: '/ak',
    name: 'AdminOrderList',
    component: AdminOrderList
  },
  {
    path: '/ol',
    name: 'OrderNoList',
    component: OrderNoList
  },
  {
    path: '/ok',
    name: 'OrderNoSearch',
    component: OrderNoSearch
  },
  {
    path: '/cl',
    name: 'ContainerNoList',
    component: ContainerNoList
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
