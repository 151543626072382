
function checkDateTime(strDate) {
    strDate = strDate.replaceAll("/","")
    strDate = strDate.replaceAll("-","")
    strDate = strDate.replaceAll(":","")
    strDate = strDate.replaceAll(" ","")
    if (!strDate.match(/^\d{4}\d{2}\d{2}\d{2}\d{2}\d{2}$/)) {
        return false;
    }

    var y = Number(strDate.slice(0, 4));
    var m = Number(strDate.slice(4, 6));
    var d = Number(strDate.slice(6, 8));
    var h = Number(strDate.slice(8, 10));
    var mi = Number(strDate.slice(10, 12));
    var ss = Number(strDate.slice(12, 14));

    var date = new Date(y, m-1, d, h, mi, ss);

    if (date.getFullYear() != y || date.getMonth()+1 != m || date.getDate() != d || date.getHours() != h || date.getMinutes() != mi || date.getSeconds() != ss) {
        return false;
    }
    return true;
}

function checkDate(strDate) {
    strDate = strDate.replaceAll("/","")
    strDate = strDate.replaceAll("-","")
    strDate = strDate.replaceAll(":","")
    strDate = strDate.replaceAll(" ","")

    if (!strDate.match(/^\d{4}\d{2}\d{2}$/)) {
        return false;
    }

    var y = Number(strDate.slice(0, 4));
    var m = Number(strDate.slice(4, 6));
    var d = Number(strDate.slice(6, 8));

    var date = new Date(y, m-1, d);

    if (date.getFullYear() != y || date.getMonth()+1 != m || date.getDate() != d) {
        return false;
    }
    return true;
}

export { checkDateTime ,checkDate}