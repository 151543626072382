import axios from "axios";
import { decycle } from "json-cyclic";

const baseURL = "";
//const baseURL1 = "app.qrqr.work/";
//const baseURL2 = "file.d2dship.com/";

export default class HttpClient {
    get(url, onSeccess, onError, onFinally) {
        console.log(url)
        try {
            axios
            .get(
                "https://" + baseURL + url,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    responseType: "json",
                } ,
            )
            .then((response) => {
                if (onSeccess !== undefined) {
                    onSeccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
        } catch (error) {
            alert(error)            
        }
    }
    getText(url, onSeccess, onError, onFinally) {
        axios
            .get(
                url,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    responseType: "text",
                } ,
            )
            .then((response) => {
                if (onSeccess !== undefined) {
                    onSeccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    save(url, params, onSeccess, onError, onFinally) {
        const save_params_json = JSON.stringify(decycle(params));
        axios
            .post("https://" + baseURL + url, save_params_json,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    responseType: "json",
                }
            )
            .then((response) => {
                if (onSeccess !== undefined) {
                    onSeccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    edit(url, params, onSeccess, onError, onFinally) {
        const edit_params_json = JSON.stringify(decycle(params));
        axios
            .put("https://" + baseURL + url, edit_params_json,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    responseType: "json",
                }
            )
            .then((response) => {
                if (onSeccess !== undefined) {
                    onSeccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    deleteWithOutToken(url, onSeccess, onError, onFinally) {
        axios
            .delete("https://" + baseURL + url)
            .then((response) => {
                if (onSeccess !== undefined) {
                    onSeccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    delete(url, token, onSeccess, onError, onFinally) {
        axios
            .delete("https://" + baseURL + url,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: token,
                    },
                    responseType: "json",
                }
            )
            .then((response) => {
                if (onSeccess !== undefined) {
                    onSeccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    deleteWithParams(url, params, onSeccess, onError, onFinally) {
        axios
            .delete("https://" + baseURL + url, { data: params })
            .then((response) => {
                if (onSeccess !== undefined) {
                    onSeccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
    }
    get2(url, onSeccess, onError, onFinally) {
        try {
            fetch(
                "https://" + baseURL + url,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    responseType: "json",
                } ,
            )
            .then((response) => {
                if (onSeccess !== undefined) {
                    onSeccess(response);
                }
            })
            .catch((error) => {
                if (onError !== undefined) {
                    onError(error);
                }
            })
            .finally(
                function () {
                    if (onFinally !== undefined) {
                        onFinally();
                    }
                }
            );
        } catch (error) {
            alert(error)            
        }
    }
}
