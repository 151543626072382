<template>
  <div class="home">
    <div class="container">
      <div class="row justify-content-end">
        <!-- <a href="/al">ｺﾝﾃﾅ配車日検索</a>&nbsp;<a href="/ak">ｵｰﾀﾞｰ配車日検索</a>&nbsp;<a href="/ok">ｵｰﾀﾞｰ検索（ｵｰﾀﾞｰ）</a> -->
        ｜<a href="/al">配車日検索（ｺﾝﾃﾅ）</a>｜&nbsp;&nbsp;&nbsp;<a href="/ok">トラック検索へ</a>
      </div>
    </div>
    <br />
    <div class="container">
      <div class="row justify-content-center">
        <div v-show="isLoading || isPwLoading" class="text-center">
          データ読み込み中です。
          <img src="../assets/loader.gif" />
        </div>
        <div v-show="!isLoading && !isAuthed">
          <form @submit.prevent="auth()">
            <div class="form-row form-inline">
              <div class="form-group">
                <div class="input-group">
                  <label for="password">パスワード：</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    size="16"
                    placeholder=""
                    v-bind:readonly="readonly"
                    :value="authNum"
                    @input="inputPassword($event.target.value)"
                  />
                  <div class="input-group-append pointer">
                    <div class="input-group-text">
                      <i class="fas fa-search" @click="auth()"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div v-show="!isLoading && isAuthed">
          <form @submit.prevent="search()">
            <div class="form-row form-inline">
              <div class="form-group">
                <div class="input-group">
                  <label for="orderNo">オーダーNo：</label>
                  <input
                    type="text"
                    class="form-control"
                    id="orderNo"
                    size="8"
                    placeholder=""
                    v-bind:readonly="readonly"
                    :value="orderNo"
                    @input="inputOrderNo($event.target.value)"
                  />
                  <div class="input-group-append pointer" id="OrderNo2">
                    <div class="input-group-text" @click="search()">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-if="isAuthed && orderNo == ''">オーダーNoを入力してください。<br /></div>
      <div v-else-if="message != ''" v-html="message"></div>
      <div v-else>
        <div v-if="isInput && (dataSet == null || dataSet.length == 0) && isSearch">
          該当のデータはありません。<br />
        </div>
        <div v-else-if="dataSet != null && dataSet.length > 0">
          <br />
          <b>{{ orderNo }}の検索結果</b>
          <br />
          <table
            class="table table-striped table-hover table-bordered table-responsive-md table-sm row-scrollable"
            id="table"
          >
            <thead>
              <tr>
                <th class="align-middle text-center pointer" scope="col" @click="sortList('order', String)">オーダーNo&nbsp;&nbsp;{{dispSortMark('order')}}</th>
                <th class="align-middle text-center pointer" scope="col" @click="sortList('dispatchNo', String)">伝票No&nbsp;&nbsp;{{dispSortMark('dispatchNo')}}</th>
                <th class="align-middle text-center pointer" scope="col" @click="sortList('driver', String)">ドライバー情報&nbsp;&nbsp;{{dispSortMark('container')}}</th>
                <th class="align-middle text-center pointer" scope="col" @click="sortList('container', String)">コンテナNo&nbsp;&nbsp;{{dispSortMark('truck')}}</th>
                <th class="align-middle text-center pointer" scope="col" @click="sortList('history', String)">履歴&nbsp;&nbsp;{{dispSortMark('history')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, idx) in dataSet" :key="idx" @click="toEdit(data)">
                <td>
                  {{ dispOrderNo(data) }}
                </td>
                <td>
                  {{ dispDispatchNo(data) }}
                </td>
                <td>
                  {{ dispDriverInfo(data) }}
                </td>
                <td>
                  {{ dispContainerInfo(data) }}
                </td>
                <td>
                  {{ dispHistory(data) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from "../modules/http";

export default {
  name: "AdminList",
  components: {},
  data: function () {
    return {
      isLoading: false,
      isPwLoading: true,
      isInput: false,
      isAuthed: false,
      isSearch: false,
      authNum: "",
      passwd: "",
      executeSerach: false,
      dataSet: null,
      data: {
        container: "",
        dispatchDate: "",
        pickupDate: "",
        departureDate: "",
        arrivalDate: "",
        complationDate: "",
        memo: "",
      },
      orderNo: "",
      message:"",
      httpClient: new HttpClient(),
      orderData: {
        isOrder: true,
        orderColumn: "history",
        isAsc: true,
        orderFunction: String,
      },
    };
  },
  created: function () {
    this.authNum = this.$cookie.getCookie("authNum");
    this.getPasswdData();
  },
  mounted:function() {
  },
  methods: {
    loadingOff(){
      this.isLoading = false;
      this.isPwLoading = false;
    },
    auth() {
      if (this.authNum == this.passwd) {
        this.isAuthed = true;
        this.$cookie.setCookie("authNum", this.passwd);
      } else {
        this.isAuthed = false;
      }
    },
    search() {
      if(this.orderNo != ""){
        this.message = ""
        this.getDataSet();
      }else{
        this.message = "オーダーNoを入力してください。"
      }
    },
    inputOrderNo(value){
      this.isInput = true
      this.orderNo = value
    },
    inputPassword(passwd){
      this.authNum = passwd
      this.auth()
    },
    sortList(name, func, asc) {
      if (asc == undefined || asc == null) {
        this.orderData.isAsc = !this.orderData.isAsc;
      } else {
        this.orderData.isAsc = asc;
      }

      this.orderData.orderColumn = name;
      this.orderData.isOrder = true;
      this.orderData.orderFunction = func;
      this.dataSet = this.dataSet.sort((a, b) => {
        if (name == "order") {
          a = this.dispOrderNo(a);
          b = this.dispOrderNo(b);
          let a_head = a.slice(0, 1);
          let b_head = b.slice(0, 1);
          let a_num = ("0000" + a.replaceAll(a_head, "")).slice(-4);
          let b_num = ("0000" + b.replaceAll(b_head, "")).slice(-4);
          a = a_head + a_num;
          b = b_head + b_num;
        } else if (name == "driver") {
          a = this.dispDriverInfo(a) + this.dispHistory(a);
          b = this.dispDriverInfo(b) + this.dispHistory(b);
        } else if (name == "container") {
          a = this.dispContainerInfo(a);
          b = this.dispContainerInfo(b);
        } else if (name == "truck") {
          a = this.dispTruckInfo(a);
          b = this.dispTruckInfo(b);
        } else {
          a = this.dispHistory(a);
          b = this.dispHistory(b);
        }
        return (
          (func(a) === func(b) ? 0 : func(a) > func(b) ? 1 : -1) *
          (this.orderData.isAsc ? 1 : -1)
        );
      });
    },
    dispOrderNo(data) {
      if (data.memo != "") {
        const driverInfo = data.memo.split("@@");
        if (driverInfo.length == 6) {
          return driverInfo[5];
        }
      }
      return "";
    },
    dispDriverInfo(data) {
      if (data.memo != "") {
        const driverInfo = data.memo.split("@@");
        if (driverInfo.length >= 5) {
          return driverInfo[0] + " " + driverInfo[2];
        }
      }
      return "";
    },
    dispContainerInfo(data) {
      if (data.container != "") {
        return data.container;
      }
      return "";
    },
    dispDispatchNo(data) {
      if (data.dispatchNo != "") {
        return data.dispatchNo;
      }
      return "";
    },
    dispTruckInfo(data) {
      if (data.truck != "") {
        return data.truck;
      }
      return "";
    },
    dispHistory(data) {
      let time = ""
      if (data.pickupDate != "") {
        time = data.pickupDate + " PickUp"
      }
      
      if (data.departureDate != "") {
        time = data.departureDate + " 出発"
      }

      if (data.arrivalDate != "") {
        time = data.arrivalDate + " 到着"
      }

      if (data.complationDate != "") {
        time = data.complationDate + " 完了"
      }

      return time
    },
    dispSortMark(name){
      let ret = ""
      if(this.orderData.orderColumn == name){
        if(this.orderData.isAsc){
          ret = "▲"
        }else{
          ret = "▼"
        }
      }
      return ret;
    },
    getDataSet() {
      this.isLoading = true;
      const url = "app.qrqr.work/containerTrace/orderlist.php?no=" + this.orderNo;
      this.httpClient.get(url, this.onSuccess, this.onError);
    },
    getPasswdData() {
      this.isPwLoading = true;
      const url = "https://app.qrqr.work/energy/read.php";
      this.httpClient.getText(url, this.onSuccessPwd, this.onErrorPwd);
    },
    onSuccess(response) {
      this.isSearch = true;
      this.loadingOff();
      let res_buff = JSON.stringify(response.data);
      res_buff = JSON.parse(res_buff);
      if("traceList" in res_buff){
        this.dataSet = res_buff.traceList;
        this.sortList('history', String,false);
      }

      this.$forceUpdate();
    },
    onError(response) {
      this.isSearch = true;
      this.loadingOff();
      console.log(response)
    },
    onSuccessPwd(response) {
      this.loadingOff();
      let pwddata = JSON.stringify(response.data);
      pwddata = JSON.parse(pwddata);
      this.passwd = pwddata.energy;

      if (this.authNum != "") {
        this.auth();
      }
      this.$forceUpdate();
    },
    onErrorPwd() {
      this.loadingOff();
      this.isAuthLoading = false;
      this.isAuthLoadingError = true;
      this.passwd = "";
    },
    toEdit(data){
      let path = "/a/"
      let routeData = this.$router.resolve({path: path,query: {qr: data.dispatchDate + data.container + "@" + data.dispatchNo}});
      window.open(routeData.href, '_blank');
    },
    convertDateFormat(strDate) {
      strDate = strDate.replaceAll("/", "");
      strDate = strDate.replaceAll("-", "");
      strDate = strDate.replaceAll(":", "");
      strDate = strDate.replaceAll(" ", "");

      return (
        strDate.slice(0, 4) +
        "/" +
        strDate.slice(4, 6) +
        "/" +
        strDate.slice(6, 8)
      );
    },
  },
};
</script>
