<template>
  <div class="home">
    <br />
    <br />
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="isLoading && isAuthLoading" class="text-center">
          データ読み込み中です。
          <img src="../assets/loader.gif" />
        </div>
        <div v-if="isAuthLoadingError">
          データを取得できませんでした。
        </div>
        <div v-else-if="!isLoading && !isAuthed">
          <form @submit.prevent="auth()">
            <div class="form-row form-inline">
              <div class="form-group">
                <div class="input-group">
                  <label for="password">パスワード：</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    size="16"
                    placeholder=""
                    v-bind:readonly="readonly"
                    :value="authNum"
                    @input="inputPassword($event.target.value)"
                  />
                  <div class="input-group-append pointer">
                    <div class="input-group-text">
                      <i class="fas fa-search" @click="auth()"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div v-else>
          <form @submit.prevent="search()">
            <div class="form-row form-inline">
              <div class="form-group">
                <div class="input-group">
                  <label for="driverCode">無線番号：</label>
                  <input
                    type="text"
                    class="form-control"
                    id="driverCode"
                    size="16"
                    placeholder=""
                    v-bind:readonly="readonly"
                    v-model="driverCode"
                    :value="driverCode"
                    @input="inputData($event.target.value)"
                  />
                  <div class="input-group-append pointer" @click="search()">
                    <div class="input-group-text">
                      <i class="fas fa-search"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div v-if="isAuthed">
        <div v-if="!isInput || driverCode == ''">{{message}}<br /></div>
        <div v-else>
          <div v-if="isInput && driverCode != ''">
            <br />
            <b>検索結果</b>
            <br />
            <div class="red" v-html="error"></div>
            <br />
            無線番号：{{ driverCode }}
            <br />
            <div
              class="row justify-content-center"
            >
              <form @submit.prevent="search()">
                <div class="form-row form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="no">車番：</label>
                      <input
                        type="text"
                        class="form-control"
                        id="no"
                        placeholder=""
                        v-model="driversData.no"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="row justify-content-center"
            >
              <form @submit.prevent="search()">
                <div class="form-row form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="name">名前：</label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder=""
                        v-model="driversData.name"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="row justify-content-center"
            >
              <form @submit.prevent="search()">
                <div class="form-row form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="tel">電話：</label>
                      <input
                        type="text"
                        class="form-control"
                        id="tel"
                        placeholder=""
                        v-model="driversData.tel"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="row justify-content-center"
            >
              <form @submit.prevent="search()">
                <div class="form-row form-inline">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="remarks">備考：</label>
                      <input
                        type="text"
                        class="form-control"
                        id="remarks"
                        placeholder=""
                        v-model="driversData.remarks"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <br />
            <div
              class="row justify-content-center"
              v-show="!isEdit"
            >
              <form @submit.prevent="search()">
                <div class="form-row form-inline col-md-3">
                  <div class="form-group">
                    <div class="input-group">
                      <label for="auth-num">暗証番号：</label>
                      <input
                        type="password"
                        class="form-control"
                        id="auth-num"
                        size="8"
                        placeholder=""
                        v-model="authNum"
                      />
                      <div class="invalid-feedback">暗証番号が違います。</div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <br />
            <br />
            <div>
              <button
                type="button"
                class="btn btn-primary btn-lg"
                @click="sendData()"
              >
                変更
              </button>
            </div>
          </div>
        
        
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpClient from "../modules/http";

export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      isLoading: true,
      isAuthLoading:true,
      isAuthed:false,
      isInput: false,
      isEdit:false,
      isQuery: false,
      authNum: "",
      passwd: "",
      executeSerach: false,
      driversDataSet: null,
      driversData: {
        cd: "",
        no: "",
        name: "",
        tel: "",
        remarks: "",
      },
      driverCode: "",
      conteinerDate: "",
      error:"",
      httpClient: new HttpClient(),
      message:"無線番号を入力してください。"
    };
  },
  created: function () {
    this.authNum = this.$cookie.getCookie("authNum");
    if(this.authNum != "" && this.authNum != null){
      this.isEdit = true;
    }
    this.getdriversData();
    this.getPasswdData();
  },
  methods: {
    auth() {
      if (this.authNum == this.passwd) {
        this.isAuthed = true;
        this.$cookie.setCookie("authNum", this.passwd);
      } else {
        this.isAuthed = false;
      }
    },
    search() {
      if(this.driverCode.length != 3){
        this.message = "無線番号が不正です。"        
      }else{
        this.isQuery = false;
        this.isInput = true;
        this.getdriversData();
      }
    },
    inputPassword(passwd){
      this.authNum = passwd
      this.auth()
    },
    getdriversData() {
      this.isLoading = true;
      const url = "file.d2dship.com/containerTrace/driver/?cd=" + this.driverCode;
      this.httpClient.get(url, this.onSuccess, this.onError);
    },
    getPasswdData(){
      this.isLoading = true;
      const url = "https://app.qrqr.work/energy/read.php";
      this.httpClient.getText(url, this.onSuccessPwd, this.onErrorPwd);
    },
    onSuccess(response) {
      this.isLoading = false;
      this.driversData = JSON.stringify(response.data);
      this.driversData = JSON.parse(this.driversData);
      this.$forceUpdate();
    },
    onEditSuccess(response) {
      this.isLoading = false;
      const elementDivAuthNum = document.getElementById("auth-num");
      elementDivAuthNum.classList.remove("is-valid");
      elementDivAuthNum.classList.remove("is-invalid");
      
      alert("完了しました。");

      this.driversData = JSON.stringify(response.data);
      this.driversData = JSON.parse(this.driversData);
      this.$forceUpdate();
    },
    onError() {},
    onSuccessPwd(response) {
      this.isAuthLoading = false;
      let pwddata = JSON.stringify(response.data);
      pwddata = JSON.parse(pwddata);
      this.passwd = pwddata.energy;
      this.auth();
      this.$forceUpdate();
    },
    onErrorPwd() {
      this.isAuthLoading = false;
      this.isAuthLoadingError = true;
      this.passwd = "";
    },
    inputData(value){
      this.driverCode = value
      if(this.driverCode.length == 3){
        this.search()
      }else{
        this.driversData.no = ""
        this.driversData.name = ""
        this.driversData.tel = ""
        this.driversData.remarks = ""

        this.isInput = false
      }
      if(this.driverCode == ""){
        this.message = "無線番号を入力してください。"        
      }else{
        this.message = ""        
      }
    },
    sendData() {
      let validation = false;
      this.isEdit = true;
      this.error = "";

      if(this.driversData.no == ""){
        validation = true;
        this.error = "車番が入力されていません。<br>"
      }

      if(this.driversData.name == ""){
        validation = true;
        this.error += "名前が入力されていません。<br>"
      }

      if(this.driversData.tel == ""){
        validation = true;
        this.error += "電話が入力されていません。<br>"
      }

      const elementDivAuthNum = document.getElementById("auth-num");
      if(this.passwd != this.authNum){
        validation = true;
        this.isEdit = false;
        elementDivAuthNum.classList.remove("is-valid");
        elementDivAuthNum.classList.add("is-invalid");
      }else{
        this.$cookie.setCookie("authNum", this.authNum);
        this.$cookie.setCookie("authNum2", "20201207");
        this.$cookie.setCookie("authNum3", "11111111");
      }
      if (!validation) {
        this.isLoading = true;

        this.driversData.cd = this.driverCode

        const url = "file.d2dship.com/containerTrace/driver/set";
        this.httpClient.edit(
          url,
          this.driversData,
          this.onEditSuccess,
          this.onError
        );
      }
    },
  },
};
</script>
