<template>
  <div class="driver">
    <div v-if="isURLError">
      <br />
      <br />
      ご指定のページがみつかりません。
    </div>
    <div v-else-if="isNoDispatchNo">
      <br />
      <br />
      伝票番号が指定されていません。
    </div>
    <div v-else-if="isNumberError && isDriverCheck && isNoContainer">
      <br />
      <br />
      <div class="container" v-if="type == 'container'">
        <div class="row justify-content-center text-center">
          <div class="col-12" v-html="dispConteinarInfo"></div>
          <div class="col-12">
            <b>コンテナ番号を入力してください。</b>
          </div>
        </div>
        <br />
        <div class="form-row form-inline justify-content-center text-center">
          <input
            type="text"
            class="form-control"
            maxlength="11"
            size="11"
            id="inputCode"
            placeholder="ｺﾝﾃﾅNo."
            pattern="^[0-9A-Za-z]+$"
            v-bind:readonly="readonly"
            v-model="containerNo"
            @input="inputContainer($event.target.value)"
          />
        </div>
        <br />
        <br />
        <div class="form-row form-inline justify-content-center text-center">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            @click="inputContainerEnter()"
          >
            O K
          </button>
        </div>
      </div>
      <div class="container" v-if="type == 'truck'">
        <div class="row justify-content-center text-center">
          <div class="col-12" v-html="dispTruckInfo"></div>
          <div class="col-12">
            <b>オーダー番号を入力してください。</b>
          </div>
        </div>
        <br />
        <div class="form-row form-inline justify-content-center text-center">
          <input
            type="text"
            class="form-control"
            maxlength="11"
            size="11"
            id="inputCode"
            pattern="^[0-9A-Za-z]+$"
            placeholder="ｵｰﾀﾞｰNo."
            v-bind:readonly="readonly"
            v-model="truckNo"
            @input="inputTruck($event.target.value)"
          />
        </div>
        <br />
        <br />
        <div class="form-row form-inline justify-content-center text-center">
          <button type="button" class="btn btn-primary btn-lg" @click="inputTruckEnter()">
            O K
          </button>
        </div>
      </div>
    </div>
    <div class="koton" v-else-if="!isOverRideCheck && isDriverCheck && !isNoContainer">
      <br />
      <br />
      <div class="container">
        <div class="justify-content-center text-center">
          <div class="card mx-auto" style="width: 18rem">
            <div class="card-header">コンテナ情報</div>
            <div class="card-body">
              <div class="justify-content-center text-center">
                <p>旧:{{ data.container }}</p>
                <p>新:{{ containerNo }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="justify-content-center text-center">
          <p>既に異なるコンテナ番号が紐づけされています。ご確認ください。</p>
        </div>
        <div class="justify-content-center text-center">
          <p>新しいコンテナ番号で上書きしますか？</p>
        </div>
        <div class="form-row form-inline justify-content-center text-center">
          <button
            type="button"
            class="btn btn-primary btn-lg btn-conf"
            @click="overRideContainerNo(true)"
          >
            は い
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-lg btn-conf"
            @click="overRideContainerNo(false)"
          >
            いいえ
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="!isAuthed">
      <br />
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-12" v-html="dispInfo"></div>
          <div class="col-12">
            <b>{{ message }}</b>
          </div>
        </div>
        <br />
        <div class="form-row form-inline justify-content-center text-center">
          <input
            type="tel"
            class="form-control"
            maxlength="3"
            size="3"
            id="inputCode"
            placeholder="無線番号"
            v-bind:readonly="readonly"
            :value="inputCode"
            @input="inputData($event.target.value)"
          />
        </div>
        <br />
        <br />
        <div class="form-row form-inline justify-content-center text-center">
          <button type="button" class="btn btn-primary btn-lg" @click="inputEnter()">
            O K
          </button>
        </div>
      </div>
    </div>
    <div class="koton" v-else-if="!isDriverCheck">
      <br />
      <br />
      <div class="container">
        <div class="justify-content-center text-center">
          <div class="card mx-auto" style="width: 18rem">
            <div class="card-header">ドライバー情報</div>
            <div class="card-body">
              <div class="justify-content-center text-center">
                <p>無線:{{ driversData.cd }}</p>
                <p>車番:{{ driversData.no }}</p>
                <p>名前:{{ driversData.name }}</p>
                <p>電話:{{ driversData.tel }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="justify-content-center text-center">
          <p>これでよろしいですか？</p>
        </div>
        <div class="form-row form-inline justify-content-center text-center">
          <button
            type="button"
            class="btn btn-primary btn-lg btn-conf"
            @click="driverCheck(true)"
          >
            は い
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-lg btn-conf"
            @click="driverCheck(false)"
          >
            いいえ
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <br />
      <br />
      <div class="container">
        <div v-if="isLoading" class="row justify-content-center text-center">
          <div class="col-12">データ読み込み中です。</div>
          <div class="col-12"><img src="../assets/loader.gif" /></div>
        </div>
        <div v-else-if="isLoadingError">データを取得できませんでした。</div>
        <div v-else>
          <div class="row">
            <div class="col-12">
              <div class="text-center" v-if="toName != ''">{{ toName }}</div>
              <div class="text-center">納入日：{{ targetDateDisp }}</div>
              <div class="text-center" v-if="type == 'container'">
                伝票№ ：{{ dispatchNo }}<br />
                ｺﾝﾃﾅ№ ：{{ containerNo }}<br />
              </div>
              <div class="text-center" v-if="type == 'truck'">
                ｵｰﾀﾞｰ№ ：{{ truckNo }}<br /><br />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="text-center"><b>追跡報告</b><br /><br /></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12" v-if="data.pickupDate != ''">
              <div class="text-center">PickUp:{{ data.pickupDate }}</div>
              <div class="text-center">
                <i class="fas fa-arrow-down blink"></i>
              </div>
            </div>
            <div v-else class="col-12">
              <button
                type="button"
                class="btn btn-primary btn-lg btn-block driver"
                @click="sendData('pickup')"
              >
                PickUp
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12" v-if="data.departureDate != ''">
              <div class="text-center">出発:{{ data.departureDate }}</div>
              <div class="text-center">
                <i class="fas fa-arrow-down blink"></i>
              </div>
            </div>
            <div class="col-12" v-if="data.pickupDate != '' && data.departureDate == ''">
              <button
                type="button"
                class="btn btn-primary btn-lg btn-block driver"
                @click="sendData('departure')"
              >
                出発
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12" v-if="data.arrivalDate != ''">
              <div class="text-center">到着:{{ data.arrivalDate }}</div>
              <div class="text-center">
                <i class="fas fa-arrow-down blink"></i>
              </div>
            </div>
            <div class="col-12" v-if="data.departureDate != '' && data.arrivalDate == ''">
              <button
                type="button"
                class="btn btn-primary btn-lg btn-block driver"
                @click="sendData('arrival')"
              >
                到着
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12" v-if="data.complationDate != ''">
              <div class="text-center">完了:{{ data.complationDate }}</div>
            </div>
            <div
              class="col-12"
              v-if="data.arrivalDate != '' && data.complationDate == ''"
            >
              <button
                type="button"
                class="btn btn-primary btn-lg btn-block driver"
                @click="sendData('complation')"
              >
                完了
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HttpClient from "../modules/http";

export default {
  name: "Home",
  components: {},
  data: function () {
    return {
      isURLError: false,
      isAuthed: false,
      isLoading: false,
      isLoadingError: false,
      isDriverCheck: false,
      isNumberError: true,
      isInputContainer: false,
      isInputTruck: false,
      isNoDispatchNo:false,
      isNoContainer: false,
      isOverRideCheck:false,
      inputCode: "",
      message: "無線番号を入力してください。",
      message2: "",
      data: {
        truck: "",
        container: "",
        dispatchNo: "",
        dispatchDate: "",
        pickupDate: "",
        departureDate: "",
        arrivalDate: "",
        complationDate: "",
        memo: "",
      },
      driversData: {
        cd: "",
        no: "",
        name: "",
        tel: "",
        remarks: "",
      },
      dispatchNo: "",
      containerNo: "",
      truckNo: "",
      targetDate: "",
      targetDateDisp: "",
      toName: "",
      agent: "",
      orderNo: "",
      dispConteinarInfo: "",
      dispTruckInfo: "",
      httpClient: null,
      type: "container",
      qrString:"",
    };
  },
  created: function () {
    this.httpClient = new HttpClient();
    if (this.$route.query != null && this.$route.query.qr != null) {
      this.qrString = this.$route.query.qr;
      let qr = this.$route.query.qr;
      let qr_array = qr.split("@");
      if (qr_array.length < 2) {
        this.isURLError = true;
        this.isLoading = false;
      } else {
        // 正常パターン
        qr = qr_array[0];
        this.dispatchNo = qr_array[1];
        if (qr.length >= 19) {
          // トラックコンテナ判別あり
          this.isURLError = false;
          this.isNumberError = false;
          this.targetDate = qr.slice(0, 8);
          this.targetDateDisp =
            this.targetDate.slice(0, 4) +
            "年" +
            this.targetDate.slice(4, 6) +
            "月" +
            this.targetDate.slice(6, 8) +
            "日";
          if (qr.slice(8, 9) == "-") {
            // トラックの場合（-あり）
            this.type = "truck";
            this.truckNo = qr.slice(9, qr.length);

            this.dispTruckInfo =
              "配車日：" +
              this.targetDateDisp +
              "<br>ｵｰﾀﾞｰ№ ：" +
              this.truckNo +
              "<br><br>";
          } else {
            // コンテナの場合（-なし）
            this.type = "container";
            this.containerNo = qr.slice(8, 19);

            this.dispConteinarInfo =
              "配車日：" +
              this.targetDateDisp +
              "<br>ｺﾝﾃﾅ№ ：" +
              this.containerNo +
              "<br>伝票№ ：";
            this.dispatchNo + "<br><br>";
            if (qr.length > 19) {
              this.orderNo = qr.slice(19, qr.length);
            }
            this.containerCheck();
          }
        } else if (8 <= qr.length && qr.length < 19) {
          // コンテナ番号無しパターン
          this.isURLError = false;
          this.isNumberError = true;
          this.targetDate = qr.slice(0, 8);
          this.targetDateDisp =
            this.targetDate.slice(0, 4) +
            "年" +
            this.targetDate.slice(4, 6) +
            "月" +
            this.targetDate.slice(6, 8) +
            "日";
          if (qr.slice(8, 9) == "-") {
            this.type = "truck";
            this.dispTruckInfo = "配車日：" + this.targetDateDisp + "<br><br>";
          } else {
            this.type = "container";
            this.orderNo = qr.slice(8, qr.length);
            this.dispConteinarInfo = "配車日：" + this.targetDateDisp + "<br><br>";
            this.isNoContainer = true;
            // this.containerCheck();
          }
        } else {
          this.isURLError = true;
        }
        this.inputCode = this.$cookie.getCookie("radioCd");
        if (this.inputCode != null && this.inputCode != "") {
          this.getDriversData();
        }
      }
    } else {
      this.isURLError = true;
      this.isLoading = false;
    }
  },
  methods: {
    loadingOff(){
      this.isLoading = false;
    },
    getDriversData() {
      this.message2 = "getDriversData()";
      this.isLoading = true;
      const url = "file.d2dship.com/containerTrace/driver/?cd=" + this.inputCode;
      this.httpClient.get(url, this.onSuccessDriver, this.onErrorDriver);
    },
    onSuccessDriver(response) {
      this.message2 = "onSuccessDriver(response)";
      this.isLoading = false;
      this.driversData = JSON.stringify(response.data);
      this.driversData = JSON.parse(this.driversData);
      if (this.driversData.no == "") {
        alert("ドライバー情報がありません。");
      } else {
        this.isAuthed = true;
      }
      this.$forceUpdate();
    },
    onErrorDriver() {
      alert("ドライバー情報を取得できませんでした。");
    },
    driverCheck(check) {
      this.isDriverCheck = check;
      if (check) {
        if (!this.isNumberError) {
          this.$cookie.setCookie("radioCd", this.inputCode);
          if (this.type == "truck") {
            this.getTruckData();
          } else {
            this.getContainerData();
          }
        }
      } else {
        this.isAuthed = false;
      }
    },
    containerCheck() {
      this.isLoading = true;
      const url =
        "app.qrqr.work/containerTrace/?con=" +
        this.containerNo +
        "&day=" +
        this.targetDate +
        "&dispatch_no=" +
        this.dispatchNo;
      this.httpClient.get(url, this.onSuccessContainerCheck, this.onError);
    },
    onSuccessContainerCheck(response) {
      this.isLoading = false;
      this.data = JSON.stringify(response.data);
      this.data = JSON.parse(this.data);
      console.log(this.data)
      if(this.data.dispatchNo != ""){
        if(this.containerNo == ""){
          this.isNoContainer = true;
        }else{
          console.log("this.containerNo:"+this.containerNo)
          console.log("this.data.container:"+this.data.container)
          if(this.containerNo != "" && this.data.container != "" && (this.data.container != this.containerNo)){
            this.isNoContainer = false;
          }else if(this.containerNo != "" && this.data.container != "" && (this.data.container == this.containerNo)){
            this.isNoContainer = true;
            this.onSuccess(response)
          }else{
            this.isNoContainer = true;
            this.onSuccess(response)
          }
        }
      }
      console.log(this.isNoContainer)

      this.$forceUpdate();
    },
    overRideContainerNo(yes){
      if(!yes){
        this.containerNo = this.data.container;
        this.isOverRideCheck = true;
      }else{
        this.updateContainerNo();
      }
    },
    updateContainerNo(){
      this.isLoading = true;

      const params = {
        "src_container":this.data.container,
        "container":this.containerNo,
        "dispatchDate":this.data.dispatchDate,
        "dispatchNo":this.dispatchNo
      }

      let url = "app.qrqr.work/containerTrace/updcon.php";
      console.log(params);
      this.httpClient.edit(url, params, this.onUpdateContainerNoSuccess, this.onError);
    },
    onUpdateContainerNoSuccess(response) {
      this.isLoading = false;
      this.data = JSON.stringify(response.data);
      this.data = JSON.parse(this.data);
      console.log(this.data)

      this.isOverRideCheck = true;

    },
    getContainerData() {
      this.isLoading = true;
      const url =
        "app.qrqr.work/containerTrace/?con=" +
        this.containerNo +
        "&day=" +
        this.targetDate +
        "&dispatch_no=" +
        this.dispatchNo;
      this.httpClient.get(url, this.onSuccess, this.onError);
    },
    getTruckData() {
      this.isLoading = true;
      const url =
        "file.d2dship.com/truckTrace/?tr=" + this.truckNo + "&day=" + this.targetDate;
      this.httpClient.get(url, this.onSuccess, this.onError);
    },
    onSuccess(response) {
      this.isLoading = false;
      this.data = JSON.stringify(response.data);
      this.data = JSON.parse(this.data);

      if(this.data.pickupDate != "" && this.data.departureDate == "" && this.data.arrivalDate == "" && this.data.arrivalDate == ""){
        // this.isDriverCheck = true;
        this.isNumberError = false;
      }else if (
        (this.data.container == "" && this.data.dispatchDate == "") ||
        this.checkDriver(this.data.memo) ||
        this.data.memo == ""
      ) {
        // this.isDriverCheck = true;
        this.isNumberError = false;

        if (this.data.pickupDate == "") {
          this.data.departureDate = "";
        }

        if (this.data.departureDate == "") {
          this.data.arrivalDate = "";
        }

        if (this.data.arrivalDate == "") {
          this.data.complationDate = "";
        }
      } else {
        this.isDriverCheck = false;
        alert("既に、他のﾄﾞﾗｲﾊﾞｰにより処理されています。");
      }

      this.$forceUpdate();
    },
    onError() {
      this.isLoading = false;
      this.isLoadingError = true;
      this.isDriverCheck = false;
      this.containerNo = "";
      if (this.type == "truck") {
        alert("オーダー情報を取得できませんでした。");
      } else {
        alert("コンテナ情報を取得できませんでした。");
      }
    },
    sendData(type) {
      this.isLoading = true;

      var date = new Date();
      var datestr =
        date.getFullYear() +
        "/" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2) +
        ":" +
        ("0" + date.getSeconds()).slice(-2);

      this.data.truck = this.truckNo;
      this.data.container = this.containerNo;
      this.data.dispatchDate = this.targetDate;
      this.data.dispatchNo = this.dispatchNo;
      this.data[type + "Date"] = datestr;
      this.data[type + "Memo"] = this.buildMemo();
      this.data["memo"] = this.buildMemo();
      let url = "app.qrqr.work/containerTrace/stamp.php";
      if (this.type == "truck") {
        url = "file.d2dship.com/truckTrace/stamp";
      }
      console.log(this.data);
      this.httpClient.edit(url, this.data, this.onSuccess, this.onError);
    },
    inputData(value) {
      this.inputCode = value;

      if (this.inputCode.length == 3) {
        this.getDriversData();
        this.$forceUpdate();
      }
    },
    inputContainer(value) {
      console.log(value);
      // alert(value);
      // this.containerNo = "";
      this.isInputContainer = true;
      this.isOverRideCheck = false;
      this.containerNo = this.containerNo.toUpperCase();
    },
    inputTruck(value) {
      console.log(value);
      this.isInputTruck = true;
      // this.truckNo = value
    },
    inputCancel() {
      this.inputNumbers = "";
      this.message2 = "";
    },
    inputEnter() {
      this.message2 = "inputEnter()";
      this.getDriversData();
      this.$forceUpdate();
    },
    inputContainerEnter() {
      if (this.containerNo.length != 11) {
        alert("コンテナ番号が不正です。");
      } else {
        var alphaCheck = new RegExp(/^[A-Z]+$/);
        var numCheck = new RegExp(/^[0-9]+$/);
        const alpha = this.containerNo.slice(0, 4);
        const num = this.containerNo.slice(4, 7);

        if (alphaCheck.test(alpha) && numCheck.test(num)) {
          this.containerCheck();
        } else {
          alert("コンテナ番号が不正です。");
        }
      }
      this.$forceUpdate();
    },
    inputTruckEnter() {
      if (this.truckNo == "") {
        alert("オーダー番号が未入力です。");
      } else {
        this.getTruckData();
      }
      this.$forceUpdate();
    },
    buildMemo() {
      return (
        this.driversData.cd +
        "@@" +
        this.driversData.no +
        "@@" +
        this.driversData.name +
        "@@" +
        this.driversData.tel +
        "@@" +
        this.driversData.remarks +
        "@@" +
        this.orderNo
      );
    },
    checkDriver(memo){
      if(memo != ""){
        const src_driver_info = memo.split("@@");
        if (this.driversData.cd != src_driver_info[0]){
          return false;
        }
        if (this.driversData.no != src_driver_info[1]){
          return false;
        }
        if (this.driversData.name != src_driver_info[2]){
          return false;
        }
        if (this.driversData.tel != src_driver_info[3]){
          return false;
        }
      }
      return true;
    }
  },
};
</script>
