<template>
  <router-view/>
</template>
<script>

export default {
  name: "app",
  mounted:function(){
    this.cookie_version = this.$cookie.getCookie("containersV")
    this.$cookie.setCookie("containersV", this.version);
    if(this.cookie_version != this.version){
      window.location.reload(true);
    }
  },
  data: function () {
    return {
      version: 9,
      cookie_version: 1,
    };
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
